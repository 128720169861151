<template>
  <!-- 保证金退款审核页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 商家昵称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 支付方式 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="支付方式">
                    <a-select v-model="queryParam.payType" allow-clear placeholder="请选择">
                      <a-select-option :value="1">支付宝</a-select-option>
                      <a-select-option :value="2">微信</a-select-option>
                      <!-- <a-select-option :value="3">余额</a-select-option> -->
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 支付状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="审核状态">
                    <a-select v-model="queryParam.status" allow-clear placeholder="请选择">
                      <a-select-option :value="1">待审核</a-select-option>
                      <a-select-option :value="2">已通过</a-select-option>
                      <a-select-option :value="3">已拒绝</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 时间 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 支付方式插槽 -->
            <span slot="payType" slot-scope="text">
              {{ text == 1 ? '支付宝' : text == 2 ? '微信' : text == 3 ? '余额' : '' }}
            </span>
            <!-- 审核状态插槽 -->
            <span slot="status" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : 'red'">
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <span v-if="record.status == 1">
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ id: record.id, status: 2 })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ id: record.id, status: 3 })">拒绝</a>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <detail-form ref="detailForm"></detail-form>
    <one-form ref="oneForm" @ok="getList"></one-form>
  </div>
</template>

<script>
import { bondRefundList, bondAuditDetail } from '@/api/modular/mallLiving/bondMoney'

import detailForm from './detailForm.vue'
import oneForm from './oneForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商家昵称',
    align: 'center',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '缴纳金额',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    align: 'center',
    scopedSlots: {
      customRender: 'payType',
    },
  },
  {
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    width: '150px',
    dataIndex: 'action',
    fixed: 'right',
    align: 'left',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {},
    }
  },
  mounted() {
    this.initTableData()
  },
  components: {
    detailForm,
    oneForm,
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date).getTime()
      return date
    },

    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      bondRefundList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
